/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Row, Col, Image, ListGroup, Card, Button, ListGroupItem, Form,  Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
//import products from '../products'
import { Link } from 'react-router-dom'
import Rating from '../components/Rating'
import {
  listProductDetails,
} from '../actions/ProductActions'
import Meta from '../components/Meta'
import Loader from '../components/Loader'
import Message from '../components/Message'
import QRCode  from 'qrcode.react';
import { 
  createOrder,
  getOrderDetails,
  getPayCode} from '../actions/OrderActions'
import { ORDER_CREATE_RESET, ORDER_PAY_CODE_RESET } from '../contents/OrderContents'
import { USER_DETAILS_RESET } from '../contents/UserContents'
import PaymentService from '../service/PaymentService.js'
import { v4 as uuidv4 } from 'uuid'
import { getUserDetails } from '../actions/UserActions'



const ProductScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1)
  const [classifyType, setClassifyType] = useState('oneYear')
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [price, setPrice] = useState(1)
  //弹出框的状态
  const [show, setShow] = useState(false)
 //支付二维码图片
 const [image, setImage] = useState('')
 const [text, setText] = useState('请扫码')
   // 扫描时间戳
   const [timer, setTimer] = useState(null);

  const dispatch = useDispatch()
  
  //=> product.productKey === match.params.productKey )
  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderCreate = useSelector((state) => state.orderCreate)
  const { orderKey, success, orderError } = orderCreate

  const orderPayCode = useSelector((state) => state.orderPayCode)
  const { payCode } = orderPayCode

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, orderDetailsLoading, orderDetailError } = orderDetails

  useEffect(() => {
      let productParam = { 
        productKey: match.params.productKey
      }

      dispatch(listProductDetails(productParam))
    // eslint-disable-next-line
  }, [dispatch, match])

  /**
   * 初始化价格
   */
  useEffect(() => {
    if(product != null && product.oneYearPrice != null) {
      setPrice(product.oneYearPrice)
    }
  }, [product])


  useEffect(() => {
    // 订单创建成功
    if (success) {
      //history.push(`/order/${orderKey}`)
      if (!userInfo) {
        history.push('/login')
      }
  
      dispatch(getOrderDetails(orderKey, userInfo.id, userInfo.token))
      dispatch({ type: ORDER_CREATE_RESET })
      dispatch({ type: USER_DETAILS_RESET })
      //console.log("orderKey=" + orderKey)

      // 获取支付二维码
      dispatch(getPayCode(orderKey));
      setShow(true);
    }
  }, [history, success, orderKey, orderCreate, order, userInfo])

  /**
   * 微信二维码
   */
  useEffect(() => {
    if(payCode !== null && payCode !== undefined) {
      if(payCode.codeUrl === null || payCode.codeUrl === undefined) {
        return ;
      } 
      //获取微信返回的支付二维码图片
      setImage(payCode.codeUrl)

      //设置定时器去监听支付
      var timesRun = 0;
      //console.log("getPayStatus orderKey="+order.orderKey);

      let timer = setInterval(() => {
        //请求支付status
        let paymentParam = {
          orderKey: order.orderKey
        }

        PaymentService.getPayStatus(paymentParam)
            .then(res => {
                //console.log("getPayStatus="+JSON.stringify(res));
                if(res == null) {
                    console.log("服务端异常！");
                    setText('服务端异常');
                    return ;
                } else if(res.retCode !== 200) {
                    setText(res.message);
                    return ;
                }
                
                if (res.retValue !== null && res.retValue.paidFlag === 1) {
                  //创建支付结果对象
                  const paymentResult = {
                    id: uuidv4(),
                    status: res.retValue.paidFlag,
                    updata_time: Date.now(),
                  }
                  //更新完成支付的订单
                  setText('您已经支付成功')
                  setShow(false)
                  clearInterval(timer)
                  dispatch({ type: ORDER_PAY_CODE_RESET })

                  // 更新数据
                  dispatch(getOrderDetails(orderKey, userInfo.id, userInfo.token))
                  // 更新用户信息
                  let accountParam = {
                    id: userInfo.id,
                    token: userInfo.token
                  }
                  dispatch(getUserDetails(accountParam))
                } else {
                  setText('请扫码')
                }
            });
        // 10分钟超时
        timesRun = timesRun + 1;
        if(timesRun >= 10) {
          clearInterval(timer)
          dispatch({ type: ORDER_PAY_CODE_RESET })
        }
      }, 3000)
      setTimer(timer);
    }
  }, [dispatch, history, payCode, orderKey, userInfo])

  /**
   * 分类操作
   * @param {*} classifyType 
   */
  const handleClassify = (classifyType) => {
    setClassifyType(classifyType);
    if(classifyType === 'oneMonth') {
      setPrice(product.oneMonthPrice)
      setClassifyType(classifyType)
    } else if(classifyType === 'oneYear') {
      setPrice(product.oneYearPrice)
      setClassifyType(classifyType)
    } else if(classifyType === 'forever'){
      setPrice(product.foreverPrice)
      setClassifyType(classifyType)
    }
  }

  /**
   * 提交订单
   */
    const submitOrderHandler = () => {
      if (!userInfo) {
        history.push('/login')
      } else {
        let orderItemList = [];
        let orderItem = {
          productKey: match.params.productKey,
          price: price,
          classifyType: classifyType,
        }
        orderItemList[0] = orderItem;
    
        dispatch(
          createOrder({
            orderItemList: orderItemList,
            paymentMethod: 'weixin',
            itemsPrice: price,
            shippingPrice: price,
            totalPrice: price,
            accountId: userInfo.id,
            token: userInfo.token
          })
        )
      }
    }

  //创建开启和关闭弹出框的函数
  const handleClose = () => {
    setShow(false)
    clearInterval(timer)
    dispatch({ type: ORDER_PAY_CODE_RESET })
  }

  return (
    <>
      <Link className='btn btn-dark my-3' to='/'>
        返回主页
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title="51风流" />
          <Row>
            <Col md={4}>
              <ListGroup variant='flush'>
                  <ListGroup.Item><h3>{product.productName}</h3></ListGroup.Item>
              
          
          
              </ListGroup>


              {price != 0  ? (
              <Card>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <Row>
                      <Col md={4}>价格：</Col>
                      <Col md={8}>
                        <strong>¥{price}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={4}>库存：</Col>
                      <Col md={8}>{'有货'}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={4}>分类：</Col>
                      <Col md={8}>
                        <Form.Control
                          as='select'
                          value={classifyType}
                          onChange={(e) => handleClassify(e.target.value)}
                        >
                          {/* <option key={1} value="oneMonth">
                              一月
                          </option> */}
                          <option key={2} value="oneYear">
                              一年
                          </option>
                          <option key={3} value="forever">
                              永久
                          </option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <Button
                        onClick={submitOrderHandler}
                        className='btn-block'
                        type='button'
                        //disabled={product.stockNum === 0}
                      >
                        立即开通
                      </Button>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>订单号：{orderKey}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>支付金额： ¥{price}</p>
                          <p>支付方式： 微信</p>
                          <Row>
                            <Col md={6} style={{ textAlign: 'center' }}>
                              <QRCode
                                    value={image}
                                    size={200}
                                    fgColor="#000000" 
                              />
                              <p
                                style={{
                                  backgroundColor: '#00C800',
                                  color: 'white',
                                }}
                              >
                                {text}
                              </p>
                            </Col>
                            <Col>
                              <Image src='/images/saoyisao.jpg' />
                            </Col>
                          </Row>
                          <p>友情提示： 需要手机或电脑打开该页面，使用另一手机进行扫码支付;</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant='primary' onClick={handleClose}>
                            关闭
                          </Button>
                        </Modal.Footer>
                      </Modal>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            ) : null}
            </Col>


           
          </Row>
          </>
      )}
    </>
  ) 
}

export default ProductScreen
